import React from 'react';
import styles from './About.module.scss';

const About = () => {
  return (
    <div className={styles.about}>
      <div className={styles.title} id='scrollAbout'>О компании</div>
      <div className={styles.container}>
        <div className={styles.text}>
        Акционерное общество «Экология строительства и производства» (АО «ЭСП») – профессиональная команда, выполняющая проектно-изыскательские работы в области инженерной экологии.
Многолетний опыт работы с государственными и частными заказчиками своих сотрудников компания успешно реализует, выполняя работы по объектам различных отраслей. Это объекты транспортной инфраструктуры, энергетики, различного производственного назначения, инфраструктуры морских портов и другие объекты, расположенные во многих субъектах Российской Федерации.
<br/><br />АО «ЭСП» является членом саморегулируемых организаций в области инженерных изысканий и архитектурно-строительного проектирования.
<br />В своей деятельности компания ориентирована на разработку грамотных решений в области инженерной экологии, достижение положительного результата в процессе сопровождения проекта при его согласовании контрольно-надзорными органами, при прохождении экологической и государственной экспертизы, а также на партнерские отношения с заказчиками и подрядными организациями.        
        </div>
      </div>
    </div>
  )
}

export default React.memo(About);