import React from 'react';
import styles from './HiddenHeader.module.scss';

const HiddenHeader = () => {
  return (
    <div className={styles.hiddenHeader}>
      
    </div>
  )
}

export default React.memo(HiddenHeader);