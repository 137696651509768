import React, { useState } from 'react';
import styles from './NewsItem.module.scss';

const NewsItem = ({img, text, date, url}) => {
  const [active, setActive] = useState();
  const [isLarge, setIsLarge] = useState(text.length > 400 ? true : false);

  return (
    <div className={styles.news}>
      <div className={styles.container}>
        <a href={url}><img className={styles.newsImage} src={img} alt="news" /></a>
        <div className={styles.newsContent}>
          <div className={styles.newsTopBlock}>
            <div className={styles.newsInfoDate}><i>{date}</i></div>
            {isLarge ? <div className={styles.newsControl} onClick={() => setActive(!active)}>{active ? 'Скрыть' : 'Показать'}</div> : <div></div>}
          </div>
          <div className={styles.newsInfoText} dangerouslySetInnerHTML={{ __html: active ? text : text.length > 400 ? text.substr(0, 400) + '...' :  text.substr(0, 400)}}></div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NewsItem);